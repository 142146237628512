@font-face {
    font-family: 'Linotype Univers 331 LightIt';
    src: url('./../fonts/LinotypeUnivers-331LightIt.eot');
    src: url('./../fonts/LinotypeUnivers-331LightIt.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/LinotypeUnivers-331LightIt.woff2') format('woff2'),
        url('./../fonts/LinotypeUnivers-331LightIt.woff') format('woff'),
        url('./../fonts/LinotypeUnivers-331LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DTLParadoxST-Italic';
    src: url('./../fonts/DTLParadoxST-Italic.eot');
    src: url('./../fonts/DTLParadoxST-Italic.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/DTLParadoxST-Italic.woff2') format('woff2'),
        url('./../fonts/DTLParadoxST-Italic.woff') format('woff'),
        url('./../fonts/DTLParadoxST-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DTLParadoxST';
    src: url('./../fonts/DTLParadoxST.eot');
    src: url('./../fonts/DTLParadoxST.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/DTLParadoxST.woff2') format('woff2'),
        url('./../fonts/DTLParadoxST.woff') format('woff'),
        url('./../fonts/DTLParadoxST.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linotype Univers 330';
    src: url('./../fonts/LinotypeUnivers-330Light.eot');
    src: url('./../fonts/LinotypeUnivers-330Light.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/LinotypeUnivers-330Light.woff2') format('woff2'),
        url('./../fonts/LinotypeUnivers-330Light.woff') format('woff'),
        url('./../fonts/LinotypeUnivers-330Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DTLParadoxST';
    src: url('./../fonts/DTLParadoxST_1.eot');
    src: url('./../fonts/DTLParadoxST_1.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/DTLParadoxST_1.woff2') format('woff2'),
        url('./../fonts/DTLParadoxST_1.woff') format('woff'),
        url('./../fonts/DTLParadoxST_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DTLParadoxST';
    src: url('./../fonts/DTLParadoxST-Italic_1.eot');
    src: url('./../fonts/DTLParadoxST-Italic_1.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/DTLParadoxST-Italic_1.woff2') format('woff2'),
        url('./../fonts/DTLParadoxST-Italic_1.woff') format('woff'),
        url('./../fonts/DTLParadoxST-Italic_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linotype Univers 331 LightIt';
    src: url('./../fonts/LinotypeUnivers-331LightIt_1.eot');
    src: url('./../fonts/LinotypeUnivers-331LightIt_1.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/LinotypeUnivers-331LightIt_1.woff2') format('woff2'),
        url('./../fonts/LinotypeUnivers-331LightIt_1.woff') format('woff'),
        url('./../fonts/LinotypeUnivers-331LightIt_1.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linotype Univers 330';
    src: url('./../fonts/LinotypeUnivers-330Light_1.eot');
    src: url('./../fonts/LinotypeUnivers-330Light_1.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/LinotypeUnivers-330Light_1.woff2') format('woff2'),
        url('./../fonts/LinotypeUnivers-330Light_1.woff') format('woff'),
        url('./../fonts/LinotypeUnivers-330Light_1.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers';
    src: url('./../fonts/Univers-Black.eot');
    src: url('./../fonts/Univers-Black.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/Univers-Black.woff2') format('woff2'),
        url('./../fonts/Univers-Black.woff') format('woff'),
        url('./../fonts/Univers-Black.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers';
    src: url('./../fonts/Univers-Black_1.eot');
    src: url('./../fonts/Univers-Black_1.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/Univers-Black_1.woff2') format('woff2'),
        url('./../fonts/Univers-Black_1.woff') format('woff'),
        url('./../fonts/Univers-Black_1.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linotype Univers 330';
    src: url('./../fonts/LinotypeUnivers-631BoldIt.eot');
    src: url('./../fonts/LinotypeUnivers-631BoldIt.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/LinotypeUnivers-631BoldIt.woff2') format('woff2'),
        url('./../fonts/LinotypeUnivers-631BoldIt.woff') format('woff'),
        url('./../fonts/LinotypeUnivers-631BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers-Light-Normal';
    src: url('./../fonts/Univers-Light-Normal.eot');
    src: url('./../fonts/Univers-Light-Normal.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/Univers-Light-Normal.woff2') format('woff2'),
        url('./../fonts/Univers-Light-Normal.woff') format('woff'),
        url('./../fonts/Univers-Light-Normal.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers';
    src: url('./../fonts/Univers-Black_2.eot');
    src: url('./../fonts/Univers-Black_2.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/Univers-Black_2.woff2') format('woff2'),
        url('./../fonts/Univers-Black_2.woff') format('woff'),
        url('./../fonts/Univers-Black_2.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 45';
    src: url('./../fonts/UniversLTStd-Bold.eot');
    src: url('./../fonts/UniversLTStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/UniversLTStd-Bold.woff2') format('woff2'),
        url('./../fonts/UniversLTStd-Bold.woff') format('woff'),
        url('./../fonts/UniversLTStd-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linotype Univers 430';
    src: url('./../fonts/LinotypeUnivers-430Regular.eot');
    src: url('./../fonts/LinotypeUnivers-430Regular.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/LinotypeUnivers-430Regular.woff2') format('woff2'),
        url('./../fonts/LinotypeUnivers-430Regular.woff') format('woff'),
        url('./../fonts/LinotypeUnivers-430Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers-Light-Normal';
    src: url('./../fonts/Univers-Light-Normal_1.eot');
    src: url('./../fonts/Univers-Light-Normal_1.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/Univers-Light-Normal_1.woff2') format('woff2'),
        url('./../fonts/Univers-Light-Normal_1.woff') format('woff'),
        url('./../fonts/Univers-Light-Normal_1.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linotype Univers 630';
    src: url('./../fonts/LinotypeUnivers-630Bold.eot');
    src: url('./../fonts/LinotypeUnivers-630Bold.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/LinotypeUnivers-630Bold.woff2') format('woff2'),
        url('./../fonts/LinotypeUnivers-630Bold.woff') format('woff'),
        url('./../fonts/LinotypeUnivers-630Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers-Light-Normal';
    src: url('./../fonts/Univers-Light-Normal_2.eot');
    src: url('./../fonts/Univers-Light-Normal_2.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/Univers-Light-Normal_2.woff2') format('woff2'),
        url('./../fonts/Univers-Light-Normal_2.woff') format('woff'),
        url('./../fonts/Univers-Light-Normal_2.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 55';
    src: url('./../fonts/UniversLTStd.eot');
    src: url('./../fonts/UniversLTStd.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/UniversLTStd.woff2') format('woff2'),
        url('./../fonts/UniversLTStd.woff') format('woff'),
        url('./../fonts/UniversLTStd.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linotype Univers 430';
    src: url('./../fonts/LinotypeUnivers-430Regular_1.eot');
    src: url('./../fonts/LinotypeUnivers-430Regular_1.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/LinotypeUnivers-430Regular_1.woff2') format('woff2'),
        url('./../fonts/LinotypeUnivers-430Regular_1.woff') format('woff'),
        url('./../fonts/LinotypeUnivers-430Regular_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 45';
    src: url('./../fonts/UniversLTStd-Bold_1.eot');
    src: url('./../fonts/UniversLTStd-Bold_1.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/UniversLTStd-Bold_1.woff2') format('woff2'),
        url('./../fonts/UniversLTStd-Bold_1.woff') format('woff'),
        url('./../fonts/UniversLTStd-Bold_1.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linotype Univers 630';
    src: url('./../fonts/LinotypeUnivers-630Bold_1.eot');
    src: url('./../fonts/LinotypeUnivers-630Bold_1.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/LinotypeUnivers-630Bold_1.woff2') format('woff2'),
        url('./../fonts/LinotypeUnivers-630Bold_1.woff') format('woff'),
        url('./../fonts/LinotypeUnivers-630Bold_1.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linotype Univers 330';
    src: url('./../fonts/LinotypeUnivers-631BoldIt_1.eot');
    src: url('./../fonts/LinotypeUnivers-631BoldIt_1.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/LinotypeUnivers-631BoldIt_1.woff2') format('woff2'),
        url('./../fonts/LinotypeUnivers-631BoldIt_1.woff') format('woff'),
        url('./../fonts/LinotypeUnivers-631BoldIt_1.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 55';
    src: url('./../fonts/UniversLTStd_1.eot');
    src: url('./../fonts/UniversLTStd_1.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/UniversLTStd_1.woff2') format('woff2'),
        url('./../fonts/UniversLTStd_1.woff') format('woff'),
        url('./../fonts/UniversLTStd_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 55';
    src: url('./../fonts/UniversLTStd_2.eot');
    src: url('./../fonts/UniversLTStd_2.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/UniversLTStd_2.woff2') format('woff2'),
        url('./../fonts/UniversLTStd_2.woff') format('woff'),
        url('./../fonts/UniversLTStd_2.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 45';
    src: url('./../fonts/UniversLTStd-Bold_2.eot');
    src: url('./../fonts/UniversLTStd-Bold_2.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/UniversLTStd-Bold_2.woff2') format('woff2'),
        url('./../fonts/UniversLTStd-Bold_2.woff') format('woff'),
        url('./../fonts/UniversLTStd-Bold_2.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}




h1
{
    font-family: 'DTLParadoxST-Italic';
    font-weight: 300;
    margin-block-start: 0;
    margin-block-end: 0;
    color: #A59C94;
}

h2, .nav-wrapper
{
    font-family: 'Univers LT Std 55';
    margin-block: 0;
    color: #A59C94;
}

h3, .menu-item
{
    font-family: 'Univers LT Std 45';
    margin-block: 0;
}

p
{
    font-family: 'Linotype Univers 430';
    margin-block: 0;
}


@media (min-width: 1024px)
{
    h1 { font-size: 3rem; }
    h2, .nav-wrapper { font-size: 1.3rem; }
    h3 { font-size: 1rem; }
    p { font-size: 1rem; }
}
