.home-left-navigation:before, .list-non-link:after, .web-cem-section:before/*, .home.page-body:before*/
{
    content: "";
    display: block;
    border-bottom: 1px solid #707070;
    margin-top: 36px;
    margin-bottom: 36px;
}

.banner-heading {
    text-align: center;
}

#root > div > div > div > div.body.home-body > div.home.row > div.home.column.page-content > div > h1,
#root > div > div > div > div.body.home-body > div.home.row > div.home.column.home-left-navigation > h1,
#root > div > div > div > div.body.home-body > div.home.row > div.home.column.home-left-navigation > ul > li:nth-child(1) > a,
#root > div > div > div > div.body.home-body > div.home.row > div.home.column.home-left-navigation > ul > li:nth-child(2) > a,
#root > div > div > div > div.body.home-body > div.home.row > div.home.column.home-left-navigation > ul > li:nth-child(3) > a,
#root > div > div > div > div.body.home-body > div.home.row > div.home.column.home-left-navigation > div > h3 {
    color: #707070;
}

/*.banner-heading {
    width: inherit;
    display: table-cell;
    height: inherit;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    position: relative;
    z-index: 2;
}*/

#root > div > div > div > div.body.home-body > div.banner-hero-outter > div > h1 {
    color: #fff;
}

.banner-hero {
    color: #fff;
    z-index: 2;
}

#banner {
    filter: brightness(0.7);
}


@media (min-width:1025px)
{
    #banner
    {
        margin: 10px auto 36px auto;
    }

    .body-image img
    {
        width: 105%;
    }

    #root > div > div > div > div.body.home-body > div.home.row > div.home.column.page-content {
        margin-right: 0;
    }

    .banner-heading { font-size: 4rem; bottom: 365px; }

    #root > div > div > div > div.body > div.row > div.column.home-left-navigation > ul { line-height: 1.3; }

    #root > div > div > div > div.body.home-body > div.home.row > div.home.column.page-content > div > h1, #root > div > div > div > div.body.home-body > div.home.row > div.home.column.home-left-navigation > h1 { font-size: 2.5rem;}
}

@media (min-width:415px) and (max-width:1024px)
{
    .page-wrapper {
        max-width: 100w;
    }

    #root > div > div > div > div.row
    {
        margin-top: 28px;
    }

    .body-image img
    {
        width: 100%;
        height: auto;
    }

    #banner {
        width: 100%;

    }

    .body-image {
        margin-top: 28px;

    }
}

@media (min-width:768px) and (max-width:1023px) {
        .column.home-left-navigation {
            margin-top: 10px;
        }
        .banner-heading { font-size: 3.5rem; bottom: 220px; }
    }

@media (min-width:1024px) and (max-width: 1199px) {
        .column.home-left-navigation {
            margin-top: 30px;
        }
        .banner-heading { font-size: 4rem; bottom: 280px; }

        #root > div > div > div > div.body.home-body > div.home.row > div.home.column.page-content > div > h1 { font-size: 2.5rem;}
    }

    
.banner-hero-outter {
    width: 411px;
    height: 166px;
    display: grid;
    place-items: center;
    position: relative;
}
.banner-hero-outter::before {
    content: "";  
    background-image: url(https://longwood-cemetery.s3.amazonaws.com/Assets/Images/414194_Longwood%2BCemetery_Davis_%2BHarold%2B_Hank_%2B_Longwood%2BVolunteer%2BPhotographer_adjusted_o.jpg);
    background-size: cover;
    position: absolute;  
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    filter: brightness(0.7);
}

@media only screen and (min-width: 320px) and (max-width: 374px) {
    .banner-hero-outter {
        width: 100%;
        height: calc(320px*(166/411));
    }
    .menu-squares-section {
        margin: auto;
    }
}
 
@media only screen and (min-width: 375px) and (max-width: 410px) {
    .banner-hero-outter {
        width: 100%;
        height: calc(375px*(166/411));
    }

    .menu-squares-section {
        margin: auto;
    }
}

@media only screen and (min-width: 411px) and (max-width: 413px) {
    .banner-hero-outter {
        width: 100%;
        height: calc(411px*(166/411));
    }

    .menu-squares-section {
        margin: auto;
    }
}

@media only screen and (min-width: 414px) and (max-width: 567px) {
    .banner-hero-outter {
        width: 100%;
        height: calc(414px*(166/411));
    }

    .menu-squares-section {
        margin: auto;
    }
}

@media only screen and (min-width: 568px) and (max-width: 666px) {
    .banner-hero-outter {
        width: 100%;
        height: calc(568px*(166/411));
    }

    .menu-squares-section {
        margin: auto;
    }
}

@media only screen and (min-width: 667px) and (max-width: 735px) {
    .banner-hero-outter {
        width: 100%;
        height: calc(667px*(166/411));
    }

    .menu-squares-section {
        margin: auto;
    }
}

@media only screen and (min-width: 736px) and (max-width: 767px) {
    .banner-hero-outter {
        width: 100%;
        height: calc(736px*(166/411));
    }

    .menu-squares-section {
        margin: auto
    }
}

@media only screen and (min-width: 768px) and (max-width: 811px) {
    .banner-hero-outter {
        width: 100%;
        height: calc(768px*(166/411));
        margin-bottom: 150px;
    }
}

@media only screen and (min-width: 812px) and (max-width: 1023px) {
    .banner-hero-outter {
        width: 100%;
        height: calc(812px*(166/411));
        margin-bottom: 150px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .banner-hero-outter {
        width: 100%;
        height: calc(1024px*(166/411));
        margin-bottom: 150px;
    }
}

@media only screen and (min-width: 1200px) {
    .banner-hero-outter {
        width: 1200px;
        height: calc(1200px*(166/411));
        margin-bottom: 150px;
    }
}

@media only screen and (max-width: 414px)
{
    #banner
    {
        width: 414px;
        height: 290px;
    }

    .banner-heading { font-size: 2rem; bottom: 180px; }

    .home.body-image
    {
        margin-top: 28px;
        width: 100px;
    }


    .home.body-image img
    {
        width: 320px;
    }

    .body { margin-top: 50px; }

    .home-body { margin-top: 75px; }
}

@media (min-width: 768px) {
    .row.menu-squares-section {
        position: relative;
        bottom: 150px;
        padding-top: 48px;
        padding-bottom: 98px;
        /* left: 30px; */
    }

    #root > div > div > div > div.body.home-body > div.home.row > div.home.column.page-content > div > h1 {
        position: relative;
        bottom: 202px;
    }

    #root > div > div > div > div.body.home-body > div.banner-hero-outter > div > h1 {
        font-size: 80px;
    }

    #root > div > div > div > div.body.home-body > div.home.row > div.home.column.home-left-navigation {
        position: relative;
        bottom: 150px;
    }
}



@media only screen and (min-width: 568px) and (max-width: 666px) and (orientation: landscape) {
    .banner-heading {
        bottom: 160px;
    }
}

@media only screen and (min-width: 667px) and (max-width: 767px) and (orientation: landscape) {
    .banner-heading {
        bottom: 200px;
    }
}
