.App {
    height: auto;
}

.indented-list .bulleted-item {
    padding-left: 25px;
    padding-bottom: 20px;
}

.divided-section {
    border-bottom: 1px solid black;
}

.guidelines-body { padding-bottom: 20px; margin-bottom: 16px; }

.unindented-list { padding-left: 0; 
    margin-left: 0px;
    margin-right: 24px;
    grid-template-columns: 100%;
    width: -moz-fit-content;
    width: fit-content;}

    .menu-title {
        position: relative;
    }

    .dropdown-ul {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .menu-item-text {
        margin: auto;
        padding: 20px;
    }
@media (max-width:767px) {
  .unindented-list { margin-left: 24px; }
}

@media (min-width: 1025px) {
    .menu-item {
        margin-bottom: 16px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 374px) {
    .custom-dropdown-menu {
        max-width: 320px;
    }

    .show-hide {
        left: 1px;
    }

    .menu-title {
        top: 20px;
    }
}
 
@media only screen and (min-width: 375px) and (max-width: 410px) {

    .menu-title {
        top: 20px;
    }
}

@media only screen and (min-width: 411px) and (max-width: 413px) {

    .menu-title {
        top: 20px;
    }
}

@media only screen and (min-width: 414px) and (max-width: 567px) {

    .menu-title {
        top: 20px;
    }

    .show-hide {
        left: 65px;
    }
}

@media only screen and (min-width: 568px) and (max-width: 666px) {

    .custom-dropdown-menu {
        max-width: 568px;
    }

    .menu-title {
        top: 20px;
    }

    .arrow-region {
        padding-left: 365px;
    }
}

@media only screen and (min-width: 667px) and (max-width: 735px) {

    .custom-dropdown-menu {
        max-width: 667px;
    }

    .arrow-region {
        padding-left: 450px;
    }

    .menu-title {
        top: 20px;
    }
}

@media only screen and (min-width: 736px) and (max-width: 767px) {

    .menu-title {
        top: 20px;
    }

    .arrow-region {
        padding-left: 520px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 811px) {

    .menu-title {
        top: 20px;
    }

    .show-hide {
        left: 15px;
    }
}

@media only screen and (min-width: 812px) and (max-width: 1023px) {

    .menu-title {
        top: 20px;
    }
    .arrow-region {
        padding-left: 630px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
    
    .menu-title {
        top: 20px;
    }

    .show-hide {
        left: 10px;
    }
}

@media only screen and (min-width: 1200px) {

}