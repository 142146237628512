.body-paragraph { padding-bottom: 5px; }

@media (min-width: 768px) {
    .history-page-text { position: relative; bottom: 10px !important;}
}

@media (max-width:767px) {
    #root > div > div > div.body > div.subpage.row.desktop-page-top > div > div > div { margin-top: 38px; }
    .history-body-image { margin-bottom: 0px !important; }
    .history-page-body { margin-top: 24px !important; }
    .body-paragraph { margin-bottom: -10px; }
}