/*
Global style rules
*/
.App
{
    border-top: 4px solid #A59C94;
    min-height: 100vh;
}
.body.home-body { max-width: 100vw; }

.page-wrapper
{
    min-height: 100vh;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.page-wrapper {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.page-wrapper:after
{
    content: "";
    display: block;
}

.page-wrapper .row
{
    display: grid;
}

.left-navigation ul, .home-left-navigation ul
{
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
}

.left-navigation ul li
{
    padding-bottom: 2em;
}

.answer {
    padding-top: 0;
    margin-top: 0;
}

.question {
    padding-bottom: 0;
    margin-bottom: 0;
    font-weight: bold;
}
a:hover {
    text-decoration: underline;
}

.top-nav.link, .menu-item a {
    text-decoration: none;
    color: #707070;
    font-family: 'Linotype Univers 630';
}

#mapLink {
    font-weight: bold;
}

#mobile-menu {
    display: none;
}

.left-nav a {
    text-decoration: none;
}
.menu-item {
    color: black;
    text-decoration: none;
    margin-bottom: 10px;
}

a, a:active { color: #3863bd }
a:hover { color: #a39c95; }

ul li, p {
  color: #000;
}
#root > div > div > div.body.faq-body > div > div.subpage.column.page-content > div > div.title.subpage-page-title > h1,
.qa-pair { padding-bottom: 20px;}

.web-cem-section {
    padding-bottom: 150px;
    max-width: 1000px;
}

/* .qa-pair { margin-bottom: 15px;} */
.icon-LC_logo_full, .icon-LG-Logo-lockup_forweb { color: black;}
@media (min-width:1025px)
{
    .App
    {
        width: 1200px;
        height: auto;
        margin: 0 auto;
        box-shadow: 0 0 25px rgba(0,0,0,0.2);
    }

    .page-wrapper .row
    {
        grid-template-columns: 25% 75%;
        margin-left: 110px;
        margin-right: 110px;
    }

    .home.row {
        display: block;
        grid-template-columns: 100%;
        margin-left: 110px;
        margin-right: 110px;   
    }


    .left-navigation
    {
        margin-right:0px;
        padding-top: 55px;
        text-align: left;
        margin-left: 0px;
        width: 75%;
        grid-row: 1;
    }

    .page-content
    {
        align-items: center;
        margin-right: 136px;
        grid-column: 2;
    }

    .body-image
    {
        width: 740px;
        height: auto;
    }

    .footer-region
    {
        margin-top: 60px;
        position: sticky;
        border-top: 4px solid #A59C94;
        height: 200px;
    }

    /*.subpage .row {
        border-top: 1px solid #A59C94;
    } */

    .desktop-page-top {
        margin-top: 20px;
    }

    .subpage .page-body {
        margin-top: 50px;
    }

    .subpage .body-image {
        margin-top: 22px;
        margin-bottom: 50px;
    }

    .section {
        margin-bottom: 50px;
    }

    .subpage .footer-region {
        margin-top:65px
    }

    .nav-title {
        font-size: 1.8rem;
        padding-top: 15px;
    }
    .icon-LC_logo_full, .icon-LG-Logo-lockup_forweb { position: absolute; font-size: calc(130px*0.75); color: black;}
    .icon-LG-Logo-lockup_forweb { position: absolute; font-size: 85px; color: black; }
    #footer-row > div.column.lockup-region > a > span { position: relative; left: 150px; margin-bottom: 20px; /*right: 180px;*/}
    .body { margin-top: 90px; }
    p { line-height: 1.4; }
    .unindented-list { padding-left: 0;}
    /*#faq-footer {
        position: relative;
        top:800px;
    }

    #visit-footer {
        position: relative;
        top: px;
    }*/

    .faq-header-region {
        border-bottom: 1px solid rgb(112, 112, 112);
        padding-bottom: 130px;
    }

    .faq-body { position: relative; bottom: 100px;}

}

@media only screen and (min-width:415px) and (max-width:1024px)
{
    .App
    {
        min-height: 100vh;

        border-top: 8px solid #A59C94;
        min-width: 100vw;
        max-width: 100vw;
    }

    .page-wrapper .row
    {
        grid-template-columns: 25% 75%;
    }

    .home.row {
        display: block;
    }

    #root > div > div > div > div.body > div.row
    {
        margin-top: 25px;
    }

    .row, .subpage.title
    {
        margin-left: 48px;
        margin-right: 48px;
        grid-template-columns: 100%;
        width: auto;
    }
    .left-navigation
    {
        grid-column: 1;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
        margin-right:32px;
        padding-top: 37px;
        text-align: left;
        margin-left: 36px;
        width: 75%;
    }
    
    .page-content, .left-navigation, .menu-squares-section
    {
        grid-column: 1;
        grid-column-start: 1;
        grid-column-end: 1;
    }

    .header-region
    {
        margin-top: 18px;
        margin-left: 24px;
        margin-right: 18px;
        margin-bottom: 18px;
    }

    .footer-region
    {
        margin-top: 60px;
        position: sticky;
        border-top: 5px solid #A59C94;
    }

    .subpage .row {
        display: block;
        margin-left: 24px;
        margin-right: 18px;
    }

    .subpage .body-image {
        margin-top: 18px;
        margin-bottom: 28px;
    }

    .guidelines-body {
        padding-bottom: 18px;
    }

    p {
        padding-bottom: 18px;
    }

    a:hover {
        text-decoration: none;
    }

    .body {
      margin-top: 90px;
    }

    p { line-height: 1.4; }

    .icon-LC_logo_full { position: absolute; font-size: calc(110px*0.75); color: #000}
    .icon-LG-Logo-lockup_forweb { position: absolute; font-size: 65px; color: black; }
    #footer-row > div.column.lockup-region > a > span { top: 50px; right: 100px;}

    #mobile-menu { margin-top: 90px; }
}

@media (min-width:769px) and (max-width: 1024px) {
      #footer-row > div.column.lockup-region > a > span { top: 50px; /*right: 280px;*/}
}

@media (min-width:415px) and (max-width:768px) {
      #footer-row > div.column.lockup-region > a > span { top: 50px; right: 100px;}
}

@media only screen and (max-width:414px)
{
    .App
    {
        min-height: 100vh;
        border-top: 4px solid #A59C94;
        max-width: 100%;
    }

    .header-region
    {
        margin-bottom: 18px;
    }

    .row, .subpage.title
    {
        margin-left: 24px;
        margin-right: 24px;
        grid-template-columns: 100%;
        width: fit-content;
    }

    .subpage.title {
        margin-top: 18px;
        margin-bottom: 18px;
    }

    .page-content, .left-navigation, .menu-squares-section
    {
        grid-column: 1;
        grid-column-start: 1;
        grid-column-end: 1;
    }

    .subpage.body-image img {
        width: 100%;
        height: auto;
    }

    .guidelines-body {
        padding-bottom: 18px;
    }

    p {
        padding-bottom: 18px;
    }

    a:hover {
        text-decoration: none;
    }

    #mobile-menu { margin-top: 75px; }

    #footer-row > div.column.lockup-region > a > span { font-size: 65px; position: relative; top:25px;}
}   


/* Menu Squares */


@media (min-width: 769px)
{
.row.menu-squares-section {
    grid-template-columns: 1fr 1fr 1fr;
}
}

#menu-square-history {
	position: relative;
	width: 300px;
	height: 200px;
}

#menu-square-visit {
	position: relative;
	width: 300px;
	height: 200px;
}

#menu-square-burials {
	position: relative;
	width: 300px;
	height: 200px;
}

#menu-square-burial-services {
	position: relative;
	width: 300px;
	height: 200px;
}

.menu-image { width: 300px; height: 200px; filter: brightness(0.7); }

.menu-link { text-decoration: none; }
.menu-link:hover { text-decoration: none; }
.menu-caption {
	/*
    float: unset;
    position: relative;
    bottom: 130px;
    left: 90px;
    width: 150px;
	*/
	text-align: center;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
    color: white;
    text-decoration: none;
    font-size: 40px;
}

/*@media(max-width:320px) {
    .menu-squares-section {
        margin-left: 10px;
    }
}

@media(min-width:321px) and (max-width:375px) {
    .menu-squares-section {
        margin-left: 30px;
    }
}

@media(min-width:376px) and (max-width:414px) {
    .menu-squares-section {
        margin-left: 50px;
    }
}*/

@media (max-width: 767px) {

    .page-top-border {display: none;}
    .row.menu-squares-section {
        grid-template-columns: 100%;
        margin-top: 48px;
    }

	 /*
    #menu-square-history {
        grid-column: 1;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
    }

    #menu-square-visit {
        grid-column: 1;
        grid-column-start: 1;
        grid-column-end:1;
        grid-row-start: 1;
        grid-row-end: 1;   
    }

    #menu-square-burials {
        grid-column: 1;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
    }
	 */
}

@media (min-width:768px){
    /*.row.menu-squares-section {
        grid-template-columns: 1fr 1fr 1fr;
    }

    #menu-square-history {
        grid-column: 1;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
        margin-right: 35px;
    }

    #menu-square-visit {
        grid-column: 2;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1; 
        margin-right: 35px;  
    }

    #menu-square-burials {
        grid-column: 3;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
    }*/

    /*.menu-squares-section { margin-left:90px; margin-bottom: 0px;} */ 
}

@media (min-width: 768px) and (max-width: 1023px)
{
   /* .menu-squares-section { margin-left:60px; margin-bottom: 36px;}   */
    
    .menu-image { width: 200px; height: 133.33px;}

    /*.menu-caption {
        bottom: 85px;
        left: 65px;
    }*/
}

@media(min-width:1024px) and (max-width:1200px) {
    .menu-squares-section {
        /* padding-left: 6px; */
        margin-right:0px;
        /*margin-left:30px;*/
    }
}

@media(max-width:1199px) {
    .page-top-border, .page-top-border-burials { display: none; }
}

@media (min-width:1200px) {
    .page-top-border { width: 100%; position: relative; top: 120px; height: 0px; }
    .page-top-border-burials { width: 100%; right: 1px; position: relative; top: 120px;}
    /*.faq-body {     
        position: relative;
        bottom: 4%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);}*/
}


/*@media(min-width:1025px) {
    .menu-squares-section {
        padding-left: 20px;
    }
}*/
