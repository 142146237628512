/*
Navigation component-specific style rules
*/

@media (min-width:1025px)
{
    .nav-large-screen
    {
        list-style-type: none;
        margin: 0;
        padding: 0;
        font-size: 18px;
    }

    .nav-large-screen ul
    {
        float: right;
        display: block;
        position: relative;
        bottom: 20px;
        left: 20px;
    }

    #headerMenu li
    {
        display: inline-block;
        padding-right: 36px;
        font-weight: bold;
        color: #707070;
    }

    #headerMenu ul li { font-size: 18px; }

    #headerMenu > li:nth-child(1) { display: none; }
}

@media only screen and (min-width:768px)
{
    #headerMenu li
    {
        /*display: none;*/
    }

}

@media only screen and (max-width:1024px)
{
    #headerMenu li
    {
        display: none;
    }
}


/*
    Mobile menu stylings
*/
.mobile-menu-icon div {
    width: 30px;
    height: 4px;
    background-color: black;
    margin: 3px 0;
  }

.mobile-menu-icon {
    display: inline-block;
    cursor: pointer;
    float: right;
    position: relative;
    top: 5px;
  }

  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
  }

  /* Rotate first bar */
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-2px, 5px) ;
    transform: rotate(-45deg) translate(-2px, 5px) ;
  }

  /* Fade out the second bar */
  .change .bar2 {
    opacity: 0;
  }

  /* Rotate last bar */
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-5px, -8px) ;
    transform: rotate(45deg) translate(-5px, -8px) ;
  }

  /*
  Overlay menu stylings
  */

  .overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 0;
    width: 0;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0,0,0); /* Black fallback color */
    background-color: rgba(0,0,0, 0.9); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  }

  /* Position the content inside the overlay */
  .overlay-content {
    position: relative;
    top: 25%; /* 25% from the top */
    width: 100%; /* 100% width */
    text-align: center; /* Centered text/links */
    margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
    text-align: start;
  }

  /* The navigation links inside the overlay */
  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block; /* Display block instead of inline */
    transition: 0.3s; /* Transition effects on hover (color) */
  }

  /* When you mouse over the navigation links, change their color */
  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
  }

  /* Position the close button (top right corner) */
  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }

  /* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
  @media screen and (max-height: 450px) {
    .overlay a {font-size: 20px}
    .overlay .closebtn {
      font-size: 40px;
      top: 15px;
      right: 35px;
    }
  }

  #mobile-menu {
    background-color: #E9E8E7;
  }

  .mobile-nav-links {
    list-style: none;
    float: left;
  }

  .mobile-nav-link {
    text-decoration: none;
    color: black;
    font-weight: bold;
  }

@media (max-width:767px) {
  .show-hide {
    left: 55px;
  }

  .mobile-menu-icon { top: 0px }
}
@media (min-width:768px) {
  .show-hide {
    left:15px;
  }
}
  /* Dropdown Arrow styles */
  .show-hide {
    margin: 10px auto;
    width: 41px;
    height:30px;
    cursor: pointer;
    position: relative;
    }
    .show-hide div {
      width: 15px;
      height: 4px;
      background-color: #fff;
      border-radius: 2px;
      position: relative;
      top: 18px;
      transition: all .4s ease-in-out;
    }
    div.left{
      transform: rotate(45deg);
      float: left;
      right: -9px;
    }
    div.right{
      transform: rotate(-45deg);
      float: right;
      left: -8px;
    }
    div.left.open{
      transform: rotate(135deg);
      right: -12px;
    }
    div.right.open{
      transform: rotate(-135deg);
      left: -14px;
    }

    .menu-title {
      color: #fff;
    }

    .custom-dropdown-menu {
      background-color: #707070;
      height: 60px;
    }

    #menuRow {
      display: grid;
      grid-template-columns: 10% 90%;
    }

    .arrow-region {
      padding-left: 197px;
    }

    .dropdown-list {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 10rem;
      padding: .5rem 0;
      margin: .125rem 0 0;
      font-size: 1rem;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      position: absolute;
      will-change: transform;
      top: 0px;
      left: 0px;
      transform: translate3d(-5px, 40px, 0px);
}
    .dropdown-list.show {
      width: 100vw;
      background-color: #707070;
      top: 187px;
      transform: translate3d(0px, 37px, 0px) !important;
      display: block !important;
    }

    .dropdown-ul{
      padding-left: 0px;
      text-align: center;
    }

    .dropdown-menu-item {
      border-bottom: 1px solid #fff;
    }
    .menu-item-text {
      color: #fff !important;
      text-align: inherit;
      margin-left: 20px;
    }
    #root > div > div > div.body > div.custom-dropdown-menu > div.dropdown-list.show > ul > div:nth-child(5)
    {
      border-bottom: none;
    }
    @media only screen and (max-width: 375px) {
     .dropdown-list.show {
       top: 187px;
     }

     .arrow-region {
      padding-left: 197px;
    }
  }

  @media (min-width: 376px) and (max-width: 414px) {
    .arrow-region {
      padding-left: 225px;
    }
  }

  @media (min-width: 415px) and (max-width: 767px) {
    .dropdown-list.show {
      top: 180px;
    }

    .arrow-region {
     padding-left: 560px;
   }
  }

  @media (min-width: 768px) and (max-width: 768px) {
    .dropdown-list.show {
      top: 210px;
    }

    .arrow-region {
     padding-left: 560px;
   }
  }

  @media (min-width: 769px) {
    .dropdown-list.show {
      top: 181px;
    }

    .arrow-region {
     padding-left: 800px;
   }
  }
