/*.row.menu-squares-section {
    grid-template-columns: 33% 33% 33%;
    padding-left: 10px;
}*/
.menu-square { margin: auto; }
#menu-square-history:before, #menu-square-visit:before, #menu-square-burials:before, 
#menu-square-burial-services:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	filter: brightness(0.6);
	background-size: contain;
}

/*.ms-home:before {
    content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	filter: brightness(0.6);
	background-size: contain;
}

div[id^="menu-square-"]:before {
    content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	filter: brightness(0.6);
	background-size: contain;
}*/

.menu-caption { text-shadow: 0 0 20px #000, 0 0 20px #000, 0 0 20px #000; }

.menu-image { width: 300px; height: 200px; }

.menu-link { text-decoration: none; }

.row.menu-squares-section {
    max-width: 1020px;
}
/*
.menu-caption {
    float: unset;
    position: relative;
    bottom: 116px;
    left: 92px;
    width: 150px;

    color: white;
    text-decoration: none;
}
*/
@media (max-width:768px) {
    .menu-squares-section {
        grid-template-columns: 100% !important;
        margin: auto;
    }

    .menu-square { margin-bottom: 24px !important; }

    #menu-square-history {
        grid-column: 1;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
    }

    #menu-square-visit {
        grid-column: 1;
        grid-column-start: 1;
        grid-column-end:1;
        grid-row-start: 1;
        grid-row-end: 1;   
    }

    #menu-square-burials {
        grid-column: 1;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
    }

    #menu-square-burial-services {
        grid-column: 1;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
    }
}
@media (min-width:769px){
    .row.menu-squares-section {
        grid-template-columns: 1fr 1fr 1fr;
        margin: auto;
        /*margin-left: 60px;
        margin-right: 60px;*/
    }

    #menu-square-history {
        grid-column: 1;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
    }

    #menu-square-visit {
        grid-column: 2;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;   
    }

    #menu-square-burials {
        grid-column: 3;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
    }

    #menu-square-burial-services {
        grid-column: 3;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
    }
}

@media (min-width: 768px) and (max-width: 1023px)
{
    .menu-squares-section { /*margin-left: 88px; margin-right: 88px;*/ margin-bottom: 36px;}   
    
    .menu-image { width: 200px; height: 133.33px;}

    .menu-square { margin-right: 36px; /*padding-left: 15px;*/ margin: auto;} 

    /*.menu-caption {bottom: 90px; left: 60px;}*/
}


