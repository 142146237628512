/*
Header-specific style rules
*/

a:hover {
    text-decoration: underline;
}

#headerLogo
{
    float: left;
}

@media (min-width:1025px)
{
    #headerLogo
    {
        height: 104.56px;
        width: 175px;
    }

    .site-header
    {
        margin-top: 36px;
        margin-left: 36px;
        margin-right: 36px;
        margin-bottom: 24px;
    }

    .cemetery-lockup > span {
      font-size: calc(130px*0.75);
      top: 44px;
      position: absolute;
    }
}

@media only screen and (min-width:415px) and (max-width:1024px)
{
    #headerLogo
    {
        height: 85.56px;
        width: 143px;
    }

    .site-header
    {
        margin-top: 18px;
        margin-left: 24px;
        margin-right: 18px;
        margin-bottom: 18px;
    }
}

@media only screen and (max-width:414px)
{
    #headerLogo
    {
        height: 85.56px;
        width: 143px;
    }

    .site-header
    {
        margin-top: 18px;
        margin-left: 24px;
        margin-right: 18px;
        margin-bottom: 18px;
    }

    .icon-LC_logo_full {
      position: absolute;
      font-size: calc(90px*0.75);
      color: black;
    }
}
