.footer
{
    width: 100%;
    background-color: #E9E8E7;
    bottom: 0px;
    right: 0;
    left: 0;
    position: relative;
    margin-top: auto;
}

.footer .row
{
    padding-bottom:56px;
}

@media (min-width: 1025px) {
    #footer-row > div.column.address-region > p {
        position: relative;
        right: 80px;
    }
}

@media (min-width:1025px)
{
    .footer
    {
        flex-shrink: 0;
    }

    .footer .row .column
    {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
    }

    .address-region
    {
        margin-top: 36px;
        margin-left: 36px;
    }

    #longwoodPropertyAnnotation
    {
        margin-bottom: 20px;
    }

    #longwoodLockup
    {
        height: auto;
        width:250px;
    }

    .address-region, .lockup-region
    {
        margin-top: 3.6em;
    }

    .lockup-region
    {
        align-items: center;
        padding-left: 260px;
        position: relative;
    }
}

@media only screen and (min-width:415px) and (max-width:1024px)
{
    .footer
    {
        flex-shrink: 0;
    }

    .footer .row .column, #footer-row
    {
        display: grid;
        /*flex-direction: column;
        flex-basis: 100%;
        flex: 1;*/
    }

    .address-region
    {
        margin-top: 36px;
        margin-left: 36px;
        grid-column: 1;
    }

    #longwoodPropertyAnnotation
    {
        margin-bottom: 20px;
    }

    #longwoodLockup
    {
        height: auto;
        width:250px;
    }

    .address-region, .lockup-region
    {
        margin-top: 3.6em;
    }

    .lockup-region
    {
        align-items: center;
        padding-left: 260px;
        grid-column: 2;
    }

}

@media only screen and (min-width:415px) and (max-width:768px)
{
}

@media (max-width: 1024px) {
    .icon-LG_Lockup {
        right: 10px;
        font-size: 100.75px !important;
    }
}

@media only screen and (max-width:414px)
{
    .footer
    {
        border-top: 4px solid #A59C94;
        display: block;
        margin-top: 60px;
    }
    .row
    {
        width: 100vw;
        margin-top: 24px;
        display: block;
    }

    #footer-row {
        display: block;
        width: 90%;
    }

    /*
    .footer .row
    {
        margin-left: 24px;
        margin-right: 16px;
    }

    .address-region
    {
        margin-top: 24px;
    }

    .lockup-region:before
    {
        content: " ";
        display: block;
        border-bottom: 3px solid #A59C94;
    }
*/
    .address-region
    {
        margin-bottom: 24px;
    }

    #longwoodPropertyAnnotation
    {
        margin-top: 24px;
    }

    #longwoodLockup
    {
        height:81.74;
        width: 221px;
    }

    /*.lockup-region
    {
        border-top: 3px solid #A59C94;
    }*/

}
